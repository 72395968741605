import Cta from "../Cta/Cta";
import ServiceCategory from "./ServiceCategory";
import { Link } from "react-router-dom";
const Home = () => {
    return (
        <main>
            <section className="Hero_section__J1BsJ">
                <picture>
                    <img 
                        alt="Hero background" 
                        loading="lazy" 
                        decoding="async"
                        style={{ 
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            color: 'transparent'
                        }}
                        sizes="100vw" 
                        srcSet="/images/banner/1.jpg?w=640&q=100 640w, 
                                /images/banner/1.jpg?w=750&q=100 750w, 
                                /images/banner/1.jpg?w=828&q=100 828w, 
                                /images/banner/1.jpg?w=1080&q=100 1080w, 
                                /images/banner/1.jpg?w=1200&q=100 1200w, 
                                /images/banner/1.jpg?w=1920&q=100 1920w, 
                                /images/banner/1.jpg?w=2048&q=100 2048w, 
                                /images/banner/1.jpg?w=3840&q=100 3840w"
                        src="/images/banner/1.jpg?w=3840&q=100"
                    />
                </picture>
                <div className="Hero_content__Zg0f2">
                    <h1>Where Creativity Meets Strategy</h1>
                </div>
            </section>

            <section className="About_section__ThI_m">
                <div className="About_container__hbFQI">
                    <span className="subheading">About us</span>
                    <h2>Accelerating growth</h2>
                    <div className="About_body__UcwcK">
                        <picture>
                            <img
                                alt="iProspect woman on the phone"
                                loading="lazy"
                                decoding="async"
                                className="responsive-img"
                                sizes="(max-width: 900px) 100vw, 33vw"
                                srcSet="
                                    /images/banner/4.jpg 256w,
                                    /images/banner/4.jpg 384w,
                                    /images/banner/4.jpg 640w,
                                    /images/banner/4.jpg 750w,
                                    /images/banner/4.jpg 828w,
                                    /images/banner/4.jpg 1080w,
                                    /images/banner/4.jpg 1200w,
                                    /images/banner/4.jpg 1920w,
                                    /images/banner/4.jpg 2048w,
                                    /images/banner/4.jpg 3840w
                                "
                                src="/assets/images/home-about.jpg"
                            />
                        </picture>
                        <div className="About_content__4_BLQ">
                            <p>
                                Digi Creators is a dynamic marketing agency that provides bespoke digital marketing solutions
                                to small and medium-sized businesses.<br />
                                <br />
                                Our services include social media strategy development,
                                content creation, advertising management, community engagement, and performance analytics.<br />
                            </p>
                            <Link className="Link_link__Awdts" to="/about">About</Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* Services Section */}
            <section className="Services_section__Cmw2Z">
                <div className="Services_container__bRINO">
                    <p className="subheading">Services</p>
                    <h2>Global digital marketing</h2>
                    <div className="Services_services__6Ox1U">
                        {/* Service Categories */}
                        <ServiceCategory/>
                    </div> 
                    <Link className="Link_link__Awdts Services_view_all__PtybR" to="/services/">View all</Link>
                </div>
            </section>
            <Cta/>
        </main>
    )
}

export default Home;
